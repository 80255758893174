export default [
  {
    lp: 1,
    img1: "xxx_hdc-as202_foto_800x600_2.png",
    img2: "xxx_hdc-as203_foto_800x600_3.png",
  },
  {
    lp: 2,
    img1: "xxx_hdc-at203_foto_800x600_1.png",
    img2: "xxx_hdc-at202_foto_800x600_2.png",
  },
  {
    lp: 3,
    img1: "xxx_hdc-vr2004jv_foto_800x600_1.png",
    img2: "xxx_hdc-vr2004jv_foto_800x600_2.png",
  },
  {
    lp: 4,
    img1: "xxx_hdc-vr2016dv_foto_800x600_1.png",
    img2: "xxx_hdc-vr2016dv_foto_800x600_2.png",
  },
]
