import React from "react"
import "../scss/Layout.scss"
import Header from "./Header"
import Footer from "./Footer"
import { ThemeProvider } from "styled-components"

const theme = {
  tayamaZielony: "#006338",
  tayamaSzary: "#4C4E53",
}
const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Header></Header>
      {children}
      <Footer></Footer>
    </ThemeProvider>
  )
}

export default Layout
