import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Wrapper = styled.div`
  margin: 2%;
  border: 1px solid gray;
  border-radius: 2%;
  box-shadow: 6px 6px 6px 0px rgba(0, 0, 0, 0.6);
`

const Obrazek = (alt, fluid, styl) => {
  return (
    <div className={styl}>
      <Wrapper>
        <a href='https://sklep.tayama.pl/oferta-handlowa,39?search=hdc'>
          <Img fluid={fluid} alt={alt} />
        </a>
      </Wrapper>
    </div>
  )
}

export default Obrazek
