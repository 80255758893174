import React from "react"
import towary from "../constants/towary"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import Obrazek from "./Obrazek"

const TrescHDC = () => {
  const data = useStaticQuery(graphql`
    query MyQueryHDC {
      allFile(filter: { relativePath: { regex: "/^xxx/i" } }) {
        nodes {
          relativePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      file(relativePath: { eq: "bg_foto.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage fluid={data.file.childImageSharp.fluid}>
      <div className='grid-container fluid'>
        {towary.map((item, index) => {
          var zgoda1 = data.allFile.nodes.filter(value => {
            return value.relativePath === item.img1
          })
          var zgoda2 = data.allFile.nodes.filter(value => {
            return value.relativePath === item.img2
          })
          return (
            <div className='grid-x align-top' key={index}>
              {Obrazek(
                item.img1,
                zgoda1[0].childImageSharp.fluid,
                "cell small-12 large-6"
              )}
              {Obrazek(
                item.img2,
                zgoda2[0].childImageSharp.fluid,
                "cell small-12 large-6"
              )}
            </div>
          )
        })}
      </div>
    </BackgroundImage>
  )
}

const Hdc = styled(TrescHDC)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y repeat-x;
`

export default Hdc
